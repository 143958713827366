import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Icon, Image, Link, List, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone, MdHelp } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services/tax-consulting-and-tax-compliance"} />
		<Helmet>
			<title>
				Tax Consulting and Tax Compliance in Cyprus | Taskmasters
			</title>
			<meta name={"description"} content={"Tax compliance in Cyprus is essential for legal, financial, reputational, and ethical reasons. By fulfilling your tax obligations, you protect your business's interests, maintain its reputation, and contribute to the overall well-being of the economy and society."} />
			<meta property={"og:title"} content={"Tax Consulting and Tax Compliance in Cyprus | Taskmasters"} />
			<meta property={"og:description"} content={"Tax compliance in Cyprus is essential for legal, financial, reputational, and ethical reasons. By fulfilling your tax obligations, you protect your business's interests, maintain its reputation, and contribute to the overall well-being of the economy and society."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/ogimage.png?v=2024-02-14T13:32:07.319Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:47.550Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:25:03.164Z"} />
		</Helmet>
		<Components.Header />
		<Section quarkly-title="Hero header 1 /1/" padding="112px 0 112px 0" sm-padding="64px 0 64px 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				display="flex"
				justify-content="space-between"
				sm-flex-direction="column"
				grid-gap="80px"
			/>
			<Box
				display="flex"
				flex-direction="column"
				justify-content="center"
				grid-gap="24px"
				width="100%"
				sm-width="100%"
				md-width="100%"
				lg-width="100%"
				lg-display="grid"
				align-items="center"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline1"
					as="h1"
					color="--primary"
					sm-font="--headline2"
					text-align="center"
					max-width="800px"
				>
					Tax Consulting and Tax Compliance
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--darkL2"
					max-width="800px"
					text-align="center"
				>
					Tax compliance in Cyprus is essential for legal, financial, reputational, and ethical reasons. By fulfilling your tax obligations, you protect your business's interests, maintain its reputation, and contribute to the overall well-being of the economy and society.
				</Text>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			sm-padding="60px 0 60px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Schedule-8"
			background="--color-lightD1"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Reasons to do it
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Tax compliance in Cyprus is crucial for several reasons
				</Text>
			</Box>
			<Box
				width="60%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 32px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				md-grid-gap="36px "
			>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="32px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Legal Obligation
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Businesses operating in Cyprus are required by law to comply with tax regulations. Failure to do so can result in penalties, fines, and legal consequences.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="32px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box
						display="flex"
						flex-direction="column"
						as="h1"
						color="--dark"
						font="--lead"
						margin="0px 0px 0px 0px"
						md-width="100%"
						text-align="left"
						width="100%"
					>
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Financial Stability
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Adhering to tax compliance ensures financial stability for your business by avoiding unexpected tax liabilities, fines, or legal fees that could negatively impact your bottom line.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="32px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Business Reputation
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Maintaining tax compliance enhances your business's reputation and credibility among customers, suppliers, investors, and other stakeholders. It demonstrates your commitment to ethical and responsible business practices.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="32px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Avoiding Penalties and Audits
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Non-compliance with tax regulations can lead to penalties, audits, and investigations by tax authorities. By ensuring tax compliance, you reduce the risk of facing these costly and time-consuming consequences.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="32px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Access to Government Benefits
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Compliance with tax regulations may be necessary to access government incentives, grants, subsidies, or other benefits available to businesses in Cyprus.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="32px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Smooth Business Operations
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							By staying compliant with tax laws, you can avoid disruptions to your business operations caused by audits, investigations, or legal disputes related to tax issues.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="32px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							International Reputation
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							For businesses operating internationally or seeking to expand globally, maintaining tax compliance in Cyprus enhances your reputation and helps you avoid scrutiny from foreign tax authorities.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="32px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Ethical Responsibility
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Paying taxes is a civic duty and contributes to the development of the economy and society. Tax compliance ensures that your business contributes its fair share to public services, infrastructure, and social welfare programs.
						</Text>
					</Box>
				</Box>
				<Box
					margin="0px 0px 0px 0px"
					display="flex"
					flex-direction="row"
					grid-column="1/3"
					sm-grid-column="auto"
				>
					<Box display="flex" flex-direction="column" padding="0px 0px 0px 48px">
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
							max-width="800px"
						>
							Overall, tax compliance in Cyprus is essential for legal, financial, reputational, and ethical reasons. By fulfilling your tax obligations, you protect your business's interests, maintain its reputation, and contribute to the overall well-being of the economy and society.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-5">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="column"
				margin="0px 0px 90px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline2"
					color="--primary"
					border-color="--color-dark"
					text-align="center"
				>
					How it works
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="center"
					md-text-align="center"
					sm-text-align="center"
					max-width="800px"
					sm-margin="0px 0px 12px 0px"
				>
					To ensure tax compliance in Cyprus, businesses should follow these steps
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
				flex-direction="column"
				align-self="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						align-items="center"
						justify-content="center"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							1
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						border-width="0 0 0 1px"
						border-style="solid"
						border-color="#c8ced7"
						padding="0px 0px 0px 20px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="--lead"
							text-align="left"
							sm-color="--primary"
						>
							Understand Tax Obligations
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							Familiarize yourself with the various taxes applicable to your business in Cyprus, including corporate income tax, value-added tax (VAT), payroll taxes, and social insurance contributions.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							2
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="--lead"
							text-align="left"
							sm-color="--primary"
						>
							Maintain Proper Records
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							Keep accurate and organized records of all financial transactions, income, expenses, assets, and liabilities. Use accounting software or hire an accountant to manage your financial records effectively.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							3
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="--lead"
							text-align="left"
							sm-color="--primary"
						>
							File Tax Returns
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							Ensure timely filing of tax returns according to the prescribed deadlines set by the Cyprus Tax Department. This includes corporate income tax returns, VAT returns, and any other relevant tax filings.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							4
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="--lead"
							text-align="left"
							sm-color="--primary"
						>
							Pay Taxes Promptly
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							Pay all taxes owed to the Cyprus Tax Department on time to avoid penalties, interest charges, or legal consequences. Set up systems to monitor tax deadlines and make payments promptly.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							5
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="--lead"
							text-align="left"
							sm-color="--primary"
						>
							Comply with VAT Regulations
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							If your business is registered for VAT, ensure compliance with VAT regulations, including issuing VAT invoices, charging the correct VAT rates, and submitting VAT returns as required.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							6
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="--lead"
							text-align="left"
							sm-color="--primary"
						>
							Manage Payroll Taxes
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							If you have employees, deduct and remit payroll taxes, including income tax and social insurance contributions, from their salaries according to Cyprus tax laws. Ensure compliance with employment tax regulations and reporting requirements.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							7
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="--lead"
							text-align="left"
							sm-color="--primary"
						>
							Seek Professional Advice
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							Consider seeking guidance from qualified accountants, tax advisors, or legal experts with knowledge of Cyprus tax laws and regulations. They can provide valuable advice on tax planning strategies, compliance requirements, and potential tax-saving opportunities.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							8
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="--lead"
							text-align="left"
							sm-color="--primary"
						>
							Stay Informed
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							Keep abreast of changes to tax laws, regulations, and deadlines issued by the Cyprus Tax Department. Attend tax seminars, workshops, or training sessions to stay informed about updates that may affect your business.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							9
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="--lead"
							text-align="left"
							sm-color="--primary"
						>
							Cooperate with Authorities
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							Cooperate with tax authorities in the event of audits, inquiries, or investigations. Provide requested documentation and information promptly and accurately to demonstrate compliance with tax regulations.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							10
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="--lead"
							text-align="left"
							sm-color="--primary"
						>
							Review Tax Strategies Regularly
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							max-width="800px"
						>
							Periodically review your tax strategies and structures to ensure they remain compliant with Cyprus tax laws and regulations. Adjust your approach as needed to optimize tax efficiency while maintaining compliance.
						</Text>
					</Box>
				</Box>
			</Box>
			<Text
				as="h1"
				margin="23px 0px 0px 0px"
				font="--lead"
				color="--dark"
				width="100%"
				md-width="100%"
				md-margin="0px 0px 32px 0px"
				md-padding="0px 0px 0px 0px"
				text-align="center"
				md-text-align="center"
				sm-text-align="center"
				max-width="800px"
				sm-margin="24px 0px 12px 0px"
			>
				By following these steps and ensuring compliance with Cyprus tax laws and regulations, businesses can minimize the risk of penalties, fines, or legal consequences while fulfilling their tax obligations effectively.
			</Text>
		</Section>
		<Section padding="100px 0 120px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-10" background="white">
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="--primary"
				font="--base"
				border-color="#dca654"
				text-transform="uppercase"
			>
				Proof of our expertise
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				lg-flex-direction="column"
				grid-gap="24px"
			>
				<Box min-width="100px" min-height="100px" width="50%" lg-width="100%">
					<Text margin="0 0px 0px 0px" font="--headline2" color="--darkL2" md-font="--headline3">
						Explore our certifications showcasing our expertise in business administration and accounting services for companies
					</Text>
				</Box>
				<Box
					display="flex"
					width="50%"
					lg-width="100%"
					lg-margin="20px 0px 0px 0px"
					md-flex-direction="column"
					md-display="flex"
					md-grid-gap="40px"
					sm-display="flex"
					sm-grid-gap="36px"
				>
					<Box
						text-align="center"
						lg-margin="0px 45px 0px 0px"
						display="flex"
						flex-direction="column"
						grid-gap="16px"
						align-items="flex-start"
						width="100%"
						lg-width="45%"
						md-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12:14:01.885Z"
							display="block"
							width="200px"
							padding="16px 16px 16px 16px"
							background="white"
							height="94px"
							srcSet="https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 1000px) 100vw,(max-width: 1279px) 100vw,100vw"
						/>
						<Text
							margin="0px 0px 0px 0px"
							font="--lead"
							text-align="center"
							href="https://www.accaglobal.com/gb/en/member/find-an-accountant/directory-of-member/results.html?isocountry=RU&FirstName=Olga&Surname=Yatsenko&Location=&inputcountrysuspended=&orgid=ACCA&orby=FNA&ipp=5&pn=1&hid=&requestcount=1"
							target="_blank"
							rel="nofollow"
							color="--darkL2"
							padding="0px 0px 0px 16px"
						>
							Certified by{" "}
							<Link href="https://www.cysec.gov.cy/en-GB/home/" rel="nofollow" target="_blank" color="--primary">
								CySec
							</Link>
						</Text>
					</Box>
					<Box
						text-align="center"
						lg-margin="0px 45px 0px 0px"
						sm-margin="0px 0 30px 0px"
						display="flex"
						flex-direction="column"
						grid-gap="16px"
						align-items="flex-start"
						width="100%"
						lg-width="45%"
						md-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-acca-full-logo.svg?v=2024-01-05T12:33:47.791Z"
							display="block"
							flex="0 0 auto"
							width="200px"
							padding="16px 16px 16px 16px"
							background="white"
							height="94px"
						/>
						<Link
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--primary"
							text-align="center"
							href="https://www.accaglobal.com/gb/en/member/find-an-accountant/directory-of-member/results.html?isocountry=RU&FirstName=Olga&Surname=Yatsenko&Location=&inputcountrysuspended=&orgid=ACCA&orby=FNA&ipp=5&pn=1&hid=&requestcount=1"
							target="_blank"
							rel="nofollow"
							padding="0px 0px 0px 16px"
						>
							ACCA member
						</Link>
						<Text
							margin="0px 0px 0px 0px"
							font="--base"
							color="--darkL2"
							text-align="left"
							padding="0px 0px 0px 16px"
							md-max-width="540px"
						>
							The Association of Chartered Certified Accountants are a globally recognised professional accountancy body providing qualifications and advancing standards in accountancy worldwide.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section color="--dark">
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Q&A
			</Text>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0" sm-font="--lead">
					What are the rates?
				</Text>
				<Text
					as="p"
					font="--lead"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					sm-font="--base"
				>
					Possible cooperation options:
				</Text>
				<List margin="0px 0px 8px 0px" padding="0px 0px 0px 20px" list-style-type="decimal" as="ol">
					<Text
						margin="0px 0px 0px 0px"
						as="p"
						color="--darkL2"
						font="--lead"
						sm-font="--base"
					>
						Fixed payment of €1,000 per month.
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						as="p"
						color="--darkL2"
						font="--lead"
						sm-font="--base"
					>
						Hourly rate of €50
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						as="p"
						color="--darkL2"
						font="--lead"
						sm-font="--base"
					>
						Hourly rate of €30 plus 2% of annual revenue.
					</Text>
				</List>
				<Text
					as="p"
					font="--lead"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					sm-font="--base"
				>
					Rates are exclusive of VAT.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0" sm-font="--lead">
					What does it take to create a "substance" company?
				</Text>
				<Components.ShowMore once>
					<Override slot="Content">
						<Text
							as="p"
							font="--lead"
							margin="0px 0px 0px 0px"
							color="--darkL2"
							sm-font="--base"
						>
							To set up a "substance" company in Cyprus, i.e. a company that demonstrates the existence of real business and economic activity on the island, you will need the following:
						</Text>
					</Override>
					<Override
						slot="Button"
						color="white"
						font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						border-radius="12px"
						margin="18px 0px 0px 0px"
					/>
					<Override slot="MoreContent">
						<List margin="8px 0px 8px 0px" padding="0px 0px 0px 20px" list-style-type="decimal" as="ol">
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Physical presence
								</Strong>
								: Your company must have a physical address in Cyprus which can be used as a registered office.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Local directors and employees
								</Strong>
								: It is understood that the company will have at least one local director and possibly several local employees depending on the volume of activity.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Functional Business
								</Strong>
								: The company must be actively engaged in business in Cyprus, including entering into contracts, performing services or manufacturing goods.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Accounting and tax accounting
								</Strong>
								: You will need to maintain accounting and tax records in accordance with Cyprus law, including filing tax returns and paying taxes on time.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Opening a bank account
								</Strong>
								: The company must have a bank account in Cyprus to carry out financial transactions and receive payments.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Documentation of activities
								</Strong>
								: Documentation of the company's activities should be maintained, including contracts, invoices, checks, reports and other financial records to prove its actual activities.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Compliance with all laws and regulations
								</Strong>
								: The company must comply with all Cyprus laws and regulations, including registration and business requirements.
							</Text>
						</List>
						<Text
							as="p"
							font="--lead"
							margin="0px 0px 0px 0px"
							color="--darkL2"
							sm-font="--base"
						>
							These steps will help your company to demonstrate that it has a real business and economic activity in Cyprus, which is essential for establishing a business in Cyprus.
						</Text>
					</Override>
				</Components.ShowMore>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"657c2c8a8d4a0c0020281f8b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});